<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.title') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-6">
                    <configuracion-empresa :permisosusuario="permisosusuario"/>
                    <configuracion-facturacion :permisosusuario="permisosusuario"/>
                    <configuracion-comunicaciones v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración') || permisosusuario.includes('Soporte')" :permisosusuario="permisosusuario"/>
                </div>
                <div class="col-md-6">
                    <configuracion-administracion :permisosusuario="permisosusuario"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

//Iconos en https://fontawesome.com/v5/search?m=free&s=solid%2Cbrands
import ConfiguracionEmpresa from './ConfiguracionEmpresa.vue'
import ConfiguracionAdministracion from './ConfiguracionAdministracion.vue';
import ConfiguracionFacturacion from './ConfiguracionFacturacion.vue';
import ConfiguracionComunicaciones from './ConfiguracionComunicaciones.vue';



//import Dialog from 'primevue/dialog';

import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {
    "configuracion-empresa": ConfiguracionEmpresa,
    "configuracion-administracion": ConfiguracionAdministracion,
    "configuracion-facturacion": ConfiguracionFacturacion,
    "configuracion-comunicaciones": ConfiguracionComunicaciones,
    
},
    data() {
        return {
            permisosusuario : [],
        }
    },
    methods:{
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            for(let permiso of permisos){
                this.permisosusuario.push(permiso.nombre_permiso);
            }
        }
    },
    mounted(){
        this.comprobarpermisos();
    }
}
</script>